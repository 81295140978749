import React from "react"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import { StaticImage } from "gatsby-plugin-image"

// @ts-ignore
import ChevronRight from "../../../assets/svg/chevronRight.svg"

import mediaContent from "./mediaContent.module.scss"
import styles from "./styles.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Склад",
					desktopTitle: <p>Всё по полочкам</p>,
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Контроль остатков, уведомления, перемещения между складами. Все
							складские операции&nbsp;&mdash; в&nbsp;одном месте: инвентаризация,
							приходные накладные, перемещения и&nbsp;списания.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/back-office-stock-1-kz.png"
							alt={"складской учет в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
					detailLink: pagesLinks.warehouse,
				},
				{
					desktopContentWidth: 490,
					tabName: "Контроль цен",
					desktopTitle: (
						<p>
							Закупай
							<br />
							по&nbsp;рыночной цене
						</p>
					),
					tabletTitle: <p>Закупай по&nbsp;рыночной цене</p>,
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Задай порог отклонения стоимости. Если он&nbsp;превышен, система
							покажет, какие цены растут слишком быстро. Возможно, стоит провести
							переговоры с&nbsp;поставщиком.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/back-office-stock-2-kz.png"
							alt={"контроль закупочных цен"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
					detailLink: pagesLinks.warehouse,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Қойма",
					desktopTitle: <p>Бәрі өз орнында</p>,
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Қалдықтарды бақылау, хабарламалар, қоймалар арасында ауыстыру. Барлық қоймалық операциялар -
							бір орында; түгендеу, кіріс жүкқұжаттары, ауысулар мен есептен шығарулар.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/back-office-stock-1-kz.png"
							alt={"складской учет в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
					detailLink: pagesLinks.warehouse,
				},
				{
					desktopContentWidth: 490,
					tabName: "Бағаларды бақылау",
					desktopTitle: (
						<p>
							Нарықтық баға<br />
							бойынша сатып алыңыз
						</p>
					),
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Құн ауытқуы шегін белгілеңіз. Егер ол асып кеткен болса, жүйе тым жылдам өсетін бағаларды
							көрсетеді. Мүмкін, жеткізушімен келіссөздер жүргізу керек шығар.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/back-office-stock-2-kz.png"
							alt={"контроль закупочных цен"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
					detailLink: pagesLinks.warehouse,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 490,
					tabName: "Склад",
					desktopTitle: <p>Порядок <br/>на полках</p>,
					tabletTitle: <p>Порядок на полках</p>,
					mobileTitle:  <p>Порядок <br/>на полках</p>,
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Контроль остатков, уведомления, перемещения между складами. Все
							складские операции&nbsp;&mdash; в&nbsp;одном месте: инвентаризация,
							приходные накладные, перемещения и&nbsp;списания.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image1}
							src="./assets/back-office-stock-1-by.png"
							alt={"складской учет в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
					detailLink: pagesLinks.warehouse,
				},
				{
					desktopContentWidth: 490,
					tabName: "Контроль цен",
					desktopTitle: (
						<p>
							Закупай
							<br />
							по&nbsp;рыночной цене
						</p>
					),
					tabletTitle: <p>Закупай по&nbsp;рыночной цене</p>,
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Задай порог отклонения стоимости. Если он&nbsp;превышен, система
							покажет, какие цены растут слишком быстро. Возможно, стоит провести
							переговоры с&nbsp;поставщиком.
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image2}
							src="./assets/back-office-stock-2-by.png"
							alt={"контроль закупочных цен"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					backdropContent: <div className={styles.backdrop} />,
					detailLink: pagesLinks.warehouse,
				}
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 490,
				tabName: "Склад",
				desktopTitle: <p>Всё по полочкам</p>,
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						Контроль остатков, уведомления, перемещения между складами. Все складские операции&nbsp;&mdash;
						в&nbsp;одной программе управления кафе: инвентаризация, приходные накладные, перемещения и&nbsp;списания.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image1}
						src="./assets/back-office-stock-1.png"
						alt={"складской учет в ресторане"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <div className={styles.backdrop} />,
				detailLink: pagesLinks.warehouse,
			},
			{
				desktopContentWidth: 490,
				tabName: "Контроль цен",
				desktopTitle: (
					<p>
						Закупай
						<br />
						по&nbsp;рыночной цене
					</p>
				),
				tabletTitle: <p>Закупай по&nbsp;рыночной цене</p>,
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						Задай порог отклонения стоимости. Если он&nbsp;превышен, система
						покажет, какие цены растут слишком быстро. Возможно, стоит провести
						переговоры с&nbsp;поставщиком.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image2}
						src="./assets/back-office-stock-2.png"
						alt={"контроль закупочных цен"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <div className={styles.backdrop} />,
				detailLink: pagesLinks.warehouse,
			},
			{
				desktopContentWidth: 490,
				tabName: "Госучёт",
				desktopTitle: <p>Забудь о штрафах</p>,
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						В&nbsp;Quick Resto уже настроена интеграция с&nbsp;ЕГАИС, Меркурием
						и&nbsp;Честным знаком. Система сама отправит сервисам необходимые
						данные и&nbsp;отразит их&nbsp;в&nbsp;бэк-офисе.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image3}
						src="./assets/back-office-stock-3.png"
						alt={"интеграции с государственными сервисами"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				backdropContent: <div className={styles.backdrop} />,
				detailLink: pagesLinks.integrations,
			},
		],
	} as {
		features: Array<FeatureGalleryItem>
	}
}
