import React from "react"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import { StaticImage } from "gatsby-plugin-image"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

// @ts-ignore
import ChevronRight from "../../../assets/svg/chevronRight.svg"
import mediaContent from "./mediaContent.module.scss"
import styles from "./styles.module.scss"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 500,
					tabName: "CRM",
					desktopTitle: "Гостю приятно, когда его узнают",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Найди гостя по&nbsp;любому ключевому запросу: имя, телефон, карта,
							номер заказа. Ещё быстрее&nbsp;&mdash; через&nbsp;QR или шритхкод
							в&nbsp;мобильном приложении и&nbsp;с&nbsp;карты лояльности
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-crm-1-kz.png"
							alt={"CRM-система ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					detailLink: pagesLinks.marketing,
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 500,
					tabName: "Маркетинг",
					desktopTitle: "Лояльнее некуда!",
					desktopContent: (
						<p>
							Сделай скидку, запусти бонусные прогруммы, предложи комбо. Детальная
							аналитика покажет какие акции приносят прибыль заведению
							и&nbsp;нравятся гостям
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-crm-2-kz.png"
							alt={"программы лояльности в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					detailLink: pagesLinks.marketing,
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 500,
					tabName: "Приложение и сайт",
					desktopTitle: "Трафик онлайн-заказов",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Фирменное приложение и&nbsp;сайт&nbsp;&mdash; быстро, бесплатно, без
							найма разработчиков. С&nbsp;подключением бонусной программы
							и&nbsp;организации заказов на&nbsp;доставку,&nbsp;&mdash; все для
							дорогих гостей.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image}
								src="./assets/back-office-crm-3-kz.png"
								alt={"сайт для доставки"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					detailLink: pagesLinks.indexWL.href,
					backdropContent: <div className={styles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 500,
					tabName: "CRM",
					desktopTitle: "Қонақты танығанда, ол үшін сүйсінерлік болады",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Қонақты кез келген негізгі сұрау бойынша табыңыз: аты, телефоны, картасы, тапсырыс нөмірі.
							Одан да тезірек - мобильді қосымшадағы QR немесе штрихкод арқылы немесе адалдық картасынан
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-crm-1-kz.png"
							alt={"CRM-система ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					detailLink: pagesLinks.marketing,
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 500,
					tabName: "Маркетинг",
					desktopTitle: "Бұдан адал болу мүмкін емес!",
					desktopContent: (
						<p>
							Жеңілдікті жасаңыз, бонустық бағдарламаларды іске қосыңыз, комбо ұсыныңыз.
							Егжей-тегжейлі аналитика мекемеге пайда әкелетін және қонақтарға ұнайтын акцияларды көрсетеді
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-crm-2-kz.png"
							alt={"программы лояльности в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					detailLink: pagesLinks.marketing,
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 500,
					tabName: "Қосымша және сайт",
					desktopTitle: "Онлайн-тапсырыстар трафигі",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Фир малық қосымша және сайт - тез, тегін, әзірлеушілерді жалдамай.
							Бонустық бағдарламаны және жеткізуге тапсырыстарды ұйымастыруды қосумен - бәрі қымбатты қонақтар үшін.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image}
								src="./assets/back-office-crm-3-kz.png"
								alt={"сайт для доставки"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					detailLink: pagesLinks.indexWL.href,
					backdropContent: <div className={styles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 500,
					tabName: "CRM",
					desktopTitle: "Гостю приятно, когда его узнают",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Найди гостя по&nbsp;любому ключевому запросу: имя, телефон, карта,
							номер заказа. Ещё быстрее&nbsp;&mdash; через&nbsp;QR или шритхкод
							в&nbsp;мобильном приложении и&nbsp;с&nbsp;карты лояльности
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-crm-1-by.png"
							alt={"CRM-система ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					detailLink: pagesLinks.marketing,
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 500,
					tabName: "Маркетинг",
					desktopTitle: "Лояльнее некуда!",
					desktopContent: (
						<p>
							Сделай скидку, запусти бонусные прогруммы, предложи комбо. Детальная
							аналитика покажет какие акции приносят прибыль заведению
							и&nbsp;нравятся гостям
						</p>
					),
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-crm-2-by.png"
							alt={"программы лояльности в ресторане"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					),
					detailLink: pagesLinks.marketing,
					backdropContent: <div className={styles.backdrop} />,
				},
				{
					desktopContentWidth: 500,
					tabName: "Приложение и сайт",
					desktopTitle: "Трафик онлайн-заказов",
					desktopContent: (
						<p className={mediaContent.smallDescription}>
							Фирменное приложение и&nbsp;сайт&nbsp;&mdash; быстро, бесплатно, без
							найма разработчиков. С&nbsp;подключением бонусной программы
							и&nbsp;организации заказов на&nbsp;доставку,&nbsp;&mdash; все для
							дорогих гостей.
						</p>
					),
					mediaContent: (
						<>
							<StaticImage
								className={mediaContent.image}
								src="./assets/back-office-crm-3-by.png"
								alt={"сайт для доставки"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</>
					),
					detailLink: pagesLinks.indexWL.href,
					backdropContent: <div className={styles.backdrop} />,
				},
			],
		} as {
			features: Array<FeatureGalleryItem>
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 500,
				tabName: "CRM",
				desktopTitle: "Гостю приятно, когда его узнают",
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						Найди гостя по&nbsp;любому ключевому запросу: имя, телефон, карта,
						номер заказа. Ещё быстрее&nbsp;&mdash; через&nbsp;QR или шритхкод
						в&nbsp;мобильном приложении и&nbsp;с&nbsp;карты лояльности
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/back-office-crm-1.png"
						alt={"CRM-система ресторана"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				detailLink: pagesLinks.marketing,
				backdropContent: <div className={styles.backdrop} />,
			},
			{
				desktopContentWidth: 500,
				tabName: "Маркетинг",
				desktopTitle: "Лояльнее некуда!",
				desktopContent: (
					<p>
						Сделай скидку, запусти бонусные программы, предложи комбо. Детальная аналитика покажет,
						какие акции приносят прибыль заведению и&nbsp;нравятся гостям.
					</p>
				),
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/back-office-crm-2.png"
						alt={"программы лояльности в ресторане"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				),
				detailLink: pagesLinks.marketing,
				backdropContent: <div className={styles.backdrop} />,
			},
			{
				desktopContentWidth: 500,
				tabName: "Приложение и сайт",
				desktopTitle: "Трафик онлайн-заказов",
				desktopContent: (
					<p className={mediaContent.smallDescription}>
						Фирменное приложение и&nbsp;сайт&nbsp;&mdash; быстро, бесплатно, без
						найма разработчиков. С&nbsp;подключением бонусной программы
						и&nbsp;организации заказов на&nbsp;доставку,&nbsp;&mdash; всё для
						дорогих гостей.
					</p>
				),
				mediaContent: (
					<>
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-crm-3.png"
							alt={"сайт для доставки"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</>
				),
				detailLink: pagesLinks.indexWL.href,
				backdropContent: <div className={styles.backdrop} />,
			},
		],
	} as {
		features: Array<FeatureGalleryItem>
	}
}

