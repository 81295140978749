import React from 'react'

export const getSeoText = () => (
  <div>
    <p>Что такое Quick Resto?</p>
    {' '}
    Quick Resto — это комплексное программное обеспечение для автоматизации
    ресторанов. Оно включает в себя управление запасами на складе, персоналом, финансами, ресторанную аналитику,
    CRM, системы лояльности и многие другие инструменты, которые помогают автоматизировать ресторанный бизнес.
    <p>Какие функции доступны в облачном бэк-офисе Quick Resto?</p>
    {' '}
    Облачный бэк-офис Quick Resto предоставляет
    различные инструменты для эффективной работы ресторана: управление меню, заказами, персоналом, аналитика продаж,
    интеграция POS-систем и другими сервисами, система лояльности и CRM.
    <p>Как работает управление заказами через бэк-офис Quick Resto?</p>
    {' '}
    Quick Resto обеспечивает полный контроль над
    заказами. Это включает следующие инструменты: онлайн-касса, поддерживающая различные платежные систем, продажа
    алкоголя и интеграция с системами ЕГАИС, Меркурий и Честный знак. Также система Quick Resto позволяет
    контролировать несколько процессов одновременно: инвентаризация продуктов, процессы заказов и поставок, а также
    оптимизировать использование инвентаря для сокращения издержек.
    <p>Как работают система лояльности и CRM для ресторанов в Quick Resto?</p>
    {' '}
    Система Quick Resto позволяет
    настраивать и управлять программами лояльности, собирать и анализировать данные о клиентах, управлять контактами
    и взаимодействиями с ними.
    <p>Как рестораторы могут эффективно осуществлять маркетинг ресторана через Quick Resto?</p>
    {' '}
    Quick Resto
    предлагает инструменты для запуска маркетинговых кампаний, акций и программ, направленных на привлечение новых
    клиентов и удержание существующих.
    <p>Как осуществляется финансовый учет и контроль персонала через систему Quick Resto?</p>
    {' '}
    В системе Quick Resto
    финансовый учет осуществляется через автоматизацию записи доходов, расходов и формирование фискальной
    отчетности. Контроль за персоналом включает учет рабочего времени, расчет заработной платы с учетом отработанных
    часов и других параметров, таких как смены и переработки. Это позволяет рестораторам эффективно управлять
    финансами и персоналом, снижая административные издержки и повышая точность учета.
    <p>Как обеспечивается поддержка 54-ФЗ в Quick Resto?</p>
    {' '}
    Quick Resto полностью соответствует требованиям 54-ФЗ,
    обеспечивая полноценную фискализацию и отчетность.
    <p>Каковы преимущества использования программного обеспечения Quick Resto для ресторанного бизнеса? </p>
    Среди
    преимуществ Quick Resto для ресторанов можно выделить следующие: эффективное управление бизнесом, автоматизация
    процессов, бизнес-аналитика, учет сотрудников и оптимизация рабочих процессов.
    <p>Какие возможности предоставляются для повышения эффективности ресторана через Quick Resto?</p>
    {' '}
    Quick Resto
    помогает в оптимизации рабочих процессов, включая инвентаризацию продуктов, управление персоналом и электронные
    меню.

  </div>
)
