import React from "react"
import SolidTabs, { SOLID_TABS_THEMES } from "../../../components/_V2/SolidTabs"
import { getData } from "./data"
import { useLocalizationContext } from "../../../localization/useLocalizationContext";

interface Props {
	className?: string
}

export default function FinanceFeatures(props: Props) {
	const localizationContext = useLocalizationContext()

	const data = getData(localizationContext.locale)

	return (
		<SolidTabs
			className={props.className}
			items={data.items}
			theme={SOLID_TABS_THEMES.DEFAULT}
			transformOnAdaptive={true}
		/>
	)
}
