import React from "react"
import { SVGProps } from "react"

const Backdrop = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="947"
		height="554"
		viewBox="0 0 947 554"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M946.999 0H2.86546C-29.4078 277.065 216.318 310.838 468.601 345.513C669.775 373.163 875.119 401.386 947 554L947 347.798L946.999 0Z"
			fill="url(#pattern0)"
			fillOpacity="0.3"
		/>
		<defs>
			<pattern
				id="pattern0"
				patternContentUnits="objectBoundingBox"
				width="0.0211193"
				height="0.0370036"
			>
				<use
					xlinkHref="#image0_4589_107370"
					transform="scale(0.000527983 0.000902527)"
				/>
			</pattern>
			<image
				id="image0_4589_107370"
				width="40"
				height="41"
				xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAgklEQVRYCe3T4QmAIBCG4QbQWzk30LMJ6tqvEQqpwP75S4Re4UBE8fNRp4mGAAIIIIAAAggggAACgwjIYrNXO0pJ3uMgse4Ykrfo1c66RNcwTMhH7hOwjBGwVUDUQn29pe+ipdb1XeY5tfR+EhdtnPfX5fRsggACCCCAAAIIIPBLgQuMFkPGdY5+vgAAAABJRU5ErkJggg=="
			/>
		</defs>
	</svg>
)

export default Backdrop
