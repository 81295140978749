import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 500,
					tabName: "Аналитика",
					desktopTitle: "Виджеты: держи руку на пульсе",
					desktopContent: (
						<p>
							Понятные графики и&nbsp;диаграммы на&nbsp;главной странице покажут как
							идут дела
						</p>
					),
					detailLink: pagesLinks.reports,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-analytics-1-kz.png"
							alt="аналитика продаж в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Финансы",
					desktopTitle: "Финансовый учёт",
					desktopContent: (
						<p>
							Покажем реальную прибыль каждого заведения. Составим учёт расходов
							на&nbsp;закупки, аренду и&nbsp;зарплаты.
						</p>
					),
					detailLink: pagesLinks.finance,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-analytics-2-kz.png"
							alt="финансовый отчет"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Отчёты",
					desktopTitle: "Когда нужно копнуть глубже",
					desktopContent: (
						<p>
							Сводные таблицы по&nbsp;типам оплат, кассирам, блюдам, столам
							и&nbsp;другим параметрам
						</p>
					),
					detailLink: pagesLinks.reports,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-analytics-3-kz.png"
							alt="отчеты по продажам в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 500,
					tabName: "Аналитика",
					desktopTitle: "Виджеттер: ісің тынысын бақылап отырыңыз",
					desktopContent: (
						<p>
							Басты беттегі түсінікті графиктер мен диаграммалар істің қалай жүріп жатқанын көрсетеді
						</p>
					),
					detailLink: pagesLinks.reports,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-analytics-1-kz.png"
							alt="аналитика продаж в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Қаржы",
					desktopTitle: "Қаржылық есеп",
					desktopContent: (
						<p>
							Әр мекеменің нақты пайдасын көрсетеміз. Сатып алуға, жалдауға және жалақыларға шығыстар есебін жасаймыз.
						</p>
					),
					detailLink: pagesLinks.finance,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-analytics-2-kz.png"
							alt="финансовый отчет"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Есептер",
					desktopTitle: "Тереңірек қазу керек болғанда",
					desktopContent: (
						<p>
							Төлемдер түрлері, кассирлер, тағамдар, үстелдер және басқа параметрлер бойынша жиынтық кестелер
						</p>
					),
					detailLink: pagesLinks.reports,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-analytics-3-kz.png"
							alt="отчеты по продажам в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 500,
					tabName: "Аналитика",
					desktopTitle: "Виджеты: держи руку на пульсе",
					desktopContent: (
						<p>
							Понятные графики и&nbsp;диаграммы на&nbsp;главной странице покажут как
							идут дела
						</p>
					),
					detailLink: pagesLinks.reports,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-analytics-1-by.png"
							alt="аналитика продаж в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Финансы",
					desktopTitle: "Финансовый учёт",
					desktopContent: (
						<p>
							Покажем реальную прибыль каждого заведения. Составим учёт расходов
							на&nbsp;закупки, аренду и&nbsp;зарплаты.
						</p>
					),
					detailLink: pagesLinks.finance,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-analytics-2-by.png"
							alt="финансовый отчет"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Отчёты",
					desktopTitle: "Когда нужно копнуть глубже",
					desktopContent: (
						<p>
							Сводные таблицы по&nbsp;типам оплат, кассирам, блюдам, столам
							и&nbsp;другим параметрам
						</p>
					),
					detailLink: pagesLinks.reports,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-analytics-3-by.png"
							alt="отчеты по продажам в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={100}
						/>
					),
				},
			],
		}

	}

	return {
		features: [
			{
				desktopContentWidth: 500,
				tabName: "Аналитика",
				desktopTitle: "Виджеты: держи руку на пульсе",
				desktopContent: (
					<p>
						Понятные графики и&nbsp;диаграммы на&nbsp;главной странице покажут как
						идут дела
					</p>
				),
				detailLink: pagesLinks.reports,
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/back-office-analytics-1.png"
						alt="аналитика продаж в ресторане"
						objectFit="contain"
						placeholder="blurred"
						quality={100}
					/>
				),
			},
			{
				desktopContentWidth: 500,
				tabName: "Финансы",
				desktopTitle: "Финансовый учёт",
				desktopContent: (
					<p>
						Покажем реальную прибыль каждого заведения. Составим учёт расходов
						на&nbsp;закупки, аренду и&nbsp;зарплаты.
					</p>
				),
				detailLink: pagesLinks.finance,
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/back-office-analytics-2.png"
						alt="финансовый отчет"
						objectFit="contain"
						placeholder="blurred"
						quality={100}
					/>
				),
			},
			{
				desktopContentWidth: 500,
				tabName: "Отчёты",
				desktopTitle: "Когда нужно копнуть глубже",
				desktopContent: (
					<p>
						Сводные таблицы по&nbsp;типам оплат, кассирам, блюдам, столам
						и&nbsp;другим параметрам
					</p>
				),
				detailLink: pagesLinks.reports,
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/back-office-analytics-3.png"
						alt="отчеты по продажам в ресторане"
						objectFit="contain"
						placeholder="blurred"
						quality={100}
					/>
				),
			},
		],
	}
}
