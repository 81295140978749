import React from "react"
import { SolidTabsProps } from "../../../components/_V2/SolidTabs"
import styles from "./styles.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			items: [
				{
					tabName: "Меню и техкарты",
					desktopTitle: "Техкарты в два счёта!",
					mobileTitle: "Техкарты в два счёта!",
					tabletTitle: "Техкарты в два счёта!",
					desktopContent: (
						<>
							Заполнить техкарту в&nbsp;Quick Resto можно и&nbsp;без опыта работы
							технологом.
							<br />
							Мы&nbsp;сделали этот процесс простым и&nbsp;понятным для всех.
						</>
					),
					tabletContent: (
						<>
							Заполнить техкарту в&nbsp;Quick Resto можно и&nbsp;без опыта работы
							технологом.
							<br />
							Мы&nbsp;сделали этот процесс простым и&nbsp;понятным для всех.
						</>
					),
					mobileContent: (
						<>
							Заполнить техкарту в&nbsp;Quick Resto можно и&nbsp;без опыта работы
							технологом.
							<br />
							Мы&nbsp;сделали этот процесс простым и&nbsp;понятным для всех.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__first)}
								src={"./assets/back-office-finance-1-kz.png"}
								alt={"технологические карты в ресторане"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
					detailLink: pagesLinks.menu,
				},
				{
					tabName: "Фудкост",
					desktopTitle: "Финансы — наглядно",
					mobileTitle: "Финансы — наглядно",
					tabletTitle: "Финансы — наглядно",
					desktopContent: (
						<>
							Контролируй фудкост и&nbsp;потери при обработке ингредиентов.
							<br />
							Система покажет в&nbsp;отчетах реальную прибыль.
						</>
					),
					tabletContent: (
						<>
							Контролируй фудкост и&nbsp;потери при обработке ингредиентов.
							<br />
							Система покажет в&nbsp;отчетах реальную прибыль.
						</>
					),
					mobileContent: (
						<>
							Контролируй фудкост и&nbsp;потери при обработке ингредиентов.
							<br />
							Система покажет в&nbsp;отчетах реальную прибыль.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__second)}
								src={"./assets/back-office-finance-2-kz.png"}
								alt={"расчет фудкоста в ресторане"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
					detailLink: pagesLinks.reports,
				},
				{
					tabName: "Допродажи и модификаторы",
					desktopTitle: "Ещё больше продаж — легко",
					mobileTitle: "Ещё больше продаж — легко",
					tabletTitle: "Ещё больше продаж — легко",
					desktopContent: (
						<>
							Допродажи и&nbsp;наборы принесут больше прибыли.
							<br />
							Введи топпинги и&nbsp;дополнения к&nbsp;блюдам, маржинальные комбо
							и&nbsp;бизнес-ланчи.
						</>
					),
					tabletContent: (
						<>
							Допродажи и&nbsp;наборы принесут больше прибыли.
							<br />
							Введи топпинги и&nbsp;дополнения к&nbsp;блюдам, маржинальные комбо
							и&nbsp;бизнес-ланчи.
						</>
					),
					mobileContent: (
						<>
							Допродажи и&nbsp;наборы принесут больше прибыли.
							<br />
							Введи топпинги и&nbsp;дополнения к&nbsp;блюдам, маржинальные комбо
							и&nbsp;бизнес-ланчи.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__third)}
								src={"./assets/back-office-finance-3-kz.png"}
								alt={"допродажи в ресторане"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
					detailLink: pagesLinks.marketing,
				},
			],
		} as SolidTabsProps
	}

	if (locale === 'kz-KZ') {
		return {
			items: [
				{
					tabName: "Мәзір және техкарталар",
					desktopTitle: "Техкарталар әп-сәтте дайын!",
					desktopContent: (
						<>
							Quick Resto-да техкартаны технолог болып жұмыс істемей-ақ толтыруға болады.
							Біз бұл процесті барлығы үшін оңай әрі түсінікті етіп жасадық.
						</>
					),

					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__first)}
								src={"./assets/back-office-finance-1-kz.png"}
								alt={"технологические карты в ресторане"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
					detailLink: pagesLinks.menu,
				},
				{
					tabName: "Фудкост",
					desktopTitle: "Қаржы - көрнекі түрде",
					desktopContent: (
						<>
							Ингредиеттерді өңдеу кезінде фудкостты және шығындарды бақылаңыз.
							<br />
							Жүйе есептерде нақты пайданы көрсетеді.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__second)}
								src={"./assets/back-office-finance-2-kz.png"}
								alt={"расчет фудкоста в ресторане"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
					detailLink: pagesLinks.reports,
				},
				{
					tabName: "Қосымша сатулар және модификаторлар",
					desktopTitle: "Одан да көп сату керек пе - оңай",
					desktopContent: (
						<>
							Қосымша сатулар мен жинақтар көбірек пайда әкеледі.
							<br />
							Тағамдарға топпингтер мен толықтырулар, марижналдық комболар мен бизнес-ланчтарды енгізіңіз.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__third)}
								src={"./assets/back-office-finance-3-kz.png"}
								alt={"допродажи в ресторане"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
					detailLink: pagesLinks.marketing,
				},
			],
		} as SolidTabsProps
	}

	if (locale === 'ru-BY') {
		return {
			items: [
				{
					tabName: "Меню и техкарты",
					desktopTitle: "С техкартой всё понятно",
					mobileTitle: "С техкартой всё понятно",
					tabletTitle: "С техкартой всё понятно",
					desktopContent: (
						<>
							Заполнить техкарту в&nbsp;Quick Resto можно и&nbsp;без опыта работы
							технологом.
							<br />
							Мы&nbsp;сделали этот процесс простым и&nbsp;понятным для всех.
						</>
					),
					tabletContent: (
						<>
							Заполнить техкарту в&nbsp;Quick Resto можно и&nbsp;без опыта работы
							технологом.
							<br />
							Мы&nbsp;сделали этот процесс простым и&nbsp;понятным для всех.
						</>
					),
					mobileContent: (
						<>
							Заполнить техкарту в&nbsp;Quick Resto можно и&nbsp;без опыта работы
							технологом.
							<br />
							Мы&nbsp;сделали этот процесс простым и&nbsp;понятным для всех.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__first)}
								src={"./assets/back-office-finance-1-by.png"}
								alt={"технологические карты в ресторане"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
					detailLink: pagesLinks.menu,
				},
				{
					tabName: "Фудкост",
					desktopTitle: "Финансы — наглядно",
					mobileTitle: "Финансы — наглядно",
					tabletTitle: "Финансы — наглядно",
					desktopContent: (
						<>
							Контролируй фудкост и&nbsp;потери при обработке ингредиентов.
							<br />
							Система покажет в&nbsp;отчетах реальную прибыль.
						</>
					),
					tabletContent: (
						<>
							Контролируй фудкост и&nbsp;потери при обработке ингредиентов.
							<br />
							Система покажет в&nbsp;отчетах реальную прибыль.
						</>
					),
					mobileContent: (
						<>
							Контролируй фудкост и&nbsp;потери при обработке ингредиентов.
							<br />
							Система покажет в&nbsp;отчетах реальную прибыль.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__second)}
								src={"./assets/back-office-finance-2-by.png"}
								alt={"расчет фудкоста в ресторане"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
					detailLink: pagesLinks.reports,
				},
				{
					tabName: "Допродажи и модификаторы",
					desktopTitle: "Ещё больше продаж — легко",
					mobileTitle: "Ещё больше продаж — легко",
					tabletTitle: "Ещё больше продаж — легко",
					desktopContent: (
						<>
							Допродажи и&nbsp;наборы принесут больше прибыли.
							<br />
							Введи топпинги и&nbsp;дополнения к&nbsp;блюдам, маржинальные комбо
							и&nbsp;бизнес-ланчи.
						</>
					),
					tabletContent: (
						<>
							Допродажи и&nbsp;наборы принесут больше прибыли.
							<br />
							Введи топпинги и&nbsp;дополнения к&nbsp;блюдам, маржинальные комбо
							и&nbsp;бизнес-ланчи.
						</>
					),
					mobileContent: (
						<>
							Допродажи и&nbsp;наборы принесут больше прибыли.
							<br />
							Введи топпинги и&nbsp;дополнения к&nbsp;блюдам, маржинальные комбо
							и&nbsp;бизнес-ланчи.
						</>
					),
					mediaContent: (
						<div className={styles.mediaContainer}>
							<StaticImage
								className={classNames(styles.mediaImage, styles.mediaImage__third)}
								src={"./assets/back-office-finance-3-by.png"}
								alt={"допродажи в ресторане"}
								objectFit="contain"
								breakpoints={[223, 642, 690]}
								quality={90}
							/>
						</div>
					),
					backdropContent: <div className={styles.backdrop}></div>,
					detailLink: pagesLinks.marketing,
				},
			],
		} as SolidTabsProps
	}

	return {
		items: [
			{
				tabName: "Меню и техкарты",
				desktopTitle: "Техкарты в два счёта!",
				mobileTitle: "Техкарты в два счёта!",
				tabletTitle: "Техкарты в два счёта!",
				desktopContent: (
					<>
						Заполнить техкарту в&nbsp;Quick Resto можно и&nbsp;без опыта работы
						технологом.
						<br />
						Мы&nbsp;сделали этот процесс простым и&nbsp;понятным для всех.
					</>
				),
				tabletContent: (
					<>
						Заполнить техкарту в&nbsp;Quick Resto можно и&nbsp;без опыта работы
						технологом.
						<br />
						Мы&nbsp;сделали этот процесс простым и&nbsp;понятным для всех.
					</>
				),
				mobileContent: (
					<>
						Заполнить техкарту в&nbsp;Quick Resto можно и&nbsp;без опыта работы
						технологом.
						<br />
						Мы&nbsp;сделали этот процесс простым и&nbsp;понятным для всех.
					</>
				),
				mediaContent: (
					<div className={styles.mediaContainer}>
						<StaticImage
							className={classNames(styles.mediaImage, styles.mediaImage__first)}
							src={"./assets/back-office-finance-1.png"}
							alt={"технологические карты в ресторане"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop}></div>,
				detailLink: pagesLinks.menu,
			},
			{
				tabName: "Фудкост",
				desktopTitle: "Финансы — наглядно",
				mobileTitle: "Финансы — наглядно",
				tabletTitle: "Финансы — наглядно",
				desktopContent: (
					<>
						Контролируй фудкост и&nbsp;потери при обработке ингредиентов.
						<br />
						Система покажет в&nbsp;отчетах реальную прибыль.
					</>
				),
				tabletContent: (
					<>
						Контролируй фудкост и&nbsp;потери при обработке ингредиентов.
						<br />
						Система покажет в&nbsp;отчетах реальную прибыль.
					</>
				),
				mobileContent: (
					<>
						Контролируй фудкост и&nbsp;потери при обработке ингредиентов.
						<br />
						Система покажет в&nbsp;отчетах реальную прибыль.
					</>
				),
				mediaContent: (
					<div className={styles.mediaContainer}>
						<StaticImage
							className={classNames(styles.mediaImage, styles.mediaImage__second)}
							src={"./assets/back-office-finance-2.png"}
							alt={"расчет фудкоста в ресторане"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop}></div>,
				detailLink: pagesLinks.reports,
			},
			{
				tabName: "Допродажи и модификаторы",
				desktopTitle: "Ещё больше золота",
				mobileTitle: "Ещё больше золота",
				tabletTitle: "Ещё больше золота",
				desktopContent: (
					<>
						Допродажи и&nbsp;наборы принесут больше прибыли.
						<br />
						Введи топинги и&nbsp;дополнения к&nbsp;блюдам, маржинальные комбо
						и&nbsp;бизнес-ланчи.
					</>
				),
				tabletContent: (
					<>
						Допродажи и&nbsp;наборы принесут больше прибыли.
						<br />
						Введи топпинги и&nbsp;дополнения к&nbsp;блюдам, маржинальные комбо
						и&nbsp;бизнес-ланчи.
					</>
				),
				mobileContent: (
					<>
						Допродажи и&nbsp;наборы принесут больше прибыли.
						<br />
						Введи топпинги и&nbsp;дополнения к&nbsp;блюдам, маржинальные комбо
						и&nbsp;бизнес-ланчи.
					</>
				),
				mediaContent: (
					<div className={styles.mediaContainer}>
						<StaticImage
							className={classNames(styles.mediaImage, styles.mediaImage__third)}
							src={"./assets/back-office-finance-3.png"}
							alt={"допродажи в ресторане"}
							objectFit="contain"
							breakpoints={[223, 642, 690]}
							quality={90}
						/>
					</div>
				),
				backdropContent: <div className={styles.backdrop}></div>,
				detailLink: pagesLinks.marketing,
			},
		],
	} as SolidTabsProps
}
