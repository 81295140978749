import React from "react"
import {Locales} from "../../../localization/types";

export const getAdvantagesSlides = (locale :Locales) => {
	if (locale === 'kz-KZ') {
		return [
			{
				title: "Аналитика мен қаржы",
				description:
					"Теңшелетін дашбордтар, есептер, аналитика, есептеулер, хабарламалар және басшыға арналған қосымша.",
			},
			{
				title: "Маркетинг және CRM",
				description:
					"Қонақтармен жұмыс істеу құралдары: акциялар, бонустар, жіберілімдер және хабарламалар.",
			},
			{
				title: "Қойманы басқару",
				description:
					"Тауар айналымы бақылауда: сатып алудан және кірістен бастап есептен шығаруға, бөлшектеу және қайта өңдеу актілеріне дейін. ",
			},
		]
	}

	return [
		{
			title: "Аналитика и финансы",
			description:
				"Настраиваемые дашборды, отчёты, аналитика, расчёты, уведомления и&nbsp;приложение для руководителя.",
		},
		{
			title: "Маркетинг и CRM",
			description:
				"Инструменты работы с&nbsp;гостями: акции, бонусы, рассылки и&nbsp;уведомления.",
		},
		{
			title: "Управление складом",
			description:
				"Товарооборот под контролем — от закупок и прихода, до списания, актов разбора и переработки.",
		},
	]
}

export default [
	{
		title: "Аналитика и финансы",
		description:
			"Настраиваемые дашборды, отчёты, аналитика, расчёты, уведомления и&nbsp;приложение для руководителя.",
	},
	{
		title: "Маркетинг и CRM",
		description:
			"Инструменты работы с&nbsp;гостями: акции, бонусы, рассылки и&nbsp;уведомления.",
	},
	{
		title: "Управление складом",
		description:
			"Товарооборот под контролем — от закупок и прихода, до списания, актов разбора и переработки.",
	},
]
