import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 500,
					tabName: "Персонал",
					desktopTitle: "Учёт рабочего времени",
					desktopContent: (
						<p>
							Экономь на&nbsp;рабочих часах бухгалтера. Зарплата персонала
							рассчитывается автоматически, с&nbsp;учетом отработанного времени,
							смен, опозданий и&nbsp;переработок.
						</p>
					),
					detailLink: pagesLinks.staff,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-accounting-1-kz.png"
							alt="учет рабочего времени"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Роли",
					desktopTitle: "Раздай роли сотрудникам",
					desktopContent: (
						<p>
							Создай несколько должностей и&nbsp;настрой доступ для каждой. Можно
							создать нестандартные должности, строго подходящие под бизнес-процессы
							предприятия.
						</p>
					),
					detailLink: pagesLinks.staff,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-accounting-2-kz.png"
							alt="управление персоналом в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Электронные чаевые",
					desktopTitle: "Дай возможность заработать больше",
					desktopContent: (
						<p>
							Подключим систему электронных чаевых: &laquo;Нетмонет&raquo;,
							CloudTips или любую другую. Гость перейдет по&nbsp;QR-коду
							на&nbsp;чеке и&nbsp;оставит благодарность конкретному официанту.
						</p>
					),
					detailLink: pagesLinks.registration,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-accounting-3-kz.png"
							alt="электронные чаевые"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 500,
					tabName: "Персонал",
					desktopTitle: "Жұмыс уақытын есепке алу",
					desktopContent: (
						<p>
							Бухгалтердің жұмыс сағаттарын үнемдеңіз. Персоналдың жалақысы жұмыс істелген уақытты,
							ауысымдарды, кешігулер мен артық жұмыс істелген уақытты есепке алумен автоматты түрде есептеледі.
						</p>
					),
					detailLink: pagesLinks.staff,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-accounting-1-kz.png"
							alt="учет рабочего времени"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Рөлдер",
					desktopTitle: "Рөлдерді қызметкерлерге таратыңыз",
					desktopContent: (
						<p>
							Бірнеше лауазым құрыңыз және әрқайсысы үшін кіру рұқсаын теңшеңіз.
							Кәсіпорынның бизнес-процестеріне қатаң сәйкес келетін әдеттегі емес лауазымдарды құруға болады.
						</p>
					),
					detailLink: pagesLinks.staff,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-accounting-2-kz.png"
							alt="управление персоналом в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Электрондық шайпұл",
					desktopTitle: "Көбірек ақша табу мүмкіндігін беріңіз ",
					desktopContent: (
						<p>
							Электрондық шайпұл жүйесін қосамыз: «Нетмонет», CloudTips немесе кез келген басқасын.
							Қонақ чектегі QR-код бойынша өтеді және нақты даяшыға алғыс қалдырады.
						</p>
					),
					detailLink: pagesLinks.registration,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-accounting-3-kz.png"
							alt="электронные чаевые"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 500,
					tabName: "Персонал",
					desktopTitle: "Учёт рабочего времени",
					desktopContent: (
						<p>
							Экономь на&nbsp;рабочих часах бухгалтера. Зарплата персонала
							рассчитывается автоматически, с&nbsp;учетом отработанного времени,
							смен, опозданий и&nbsp;переработок.
						</p>
					),
					detailLink: pagesLinks.staff,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-accounting-1-by.png"
							alt="учет рабочего времени"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Роли",
					desktopTitle: "Раздай роли сотрудникам",
					desktopContent: (
						<p>
							Создай несколько должностей и&nbsp;настрой доступ для каждой. Можно
							создать нестандартные должности, строго подходящие под бизнес-процессы
							предприятия.
						</p>
					),
					detailLink: pagesLinks.staff,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-accounting-2-by.png"
							alt="управление персоналом в ресторане"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					desktopContentWidth: 500,
					tabName: "Электронные чаевые",
					desktopTitle: "Дай возможность заработать больше",
					desktopContent: (
						<p>
							Подключим систему электронных чаевых: &laquo;Нетмонет&raquo;,
							CloudTips или любую другую. Гость перейдет по&nbsp;QR-коду
							на&nbsp;чеке и&nbsp;оставит благодарность конкретному официанту.
						</p>
					),
					detailLink: pagesLinks.registration,
					mediaContent: (
						<StaticImage
							className={mediaContent.image}
							src="./assets/back-office-accounting-3-by.png"
							alt="электронные чаевые"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
			],
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 500,
				tabName: "Персонал",
				desktopTitle: "Учёт рабочего времени",
				desktopContent: (
					<p>
						Экономь на&nbsp;рабочих часах бухгалтера. Программа управления рестораном автоматически
						рассчитает зарплату персонала с&nbsp;учётом отработанного времени, смен,
						опозданий и&nbsp;переработок.
					</p>
				),
				detailLink: pagesLinks.staff,
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/back-office-accounting-1.png"
						alt="учет рабочего времени"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				desktopContentWidth: 500,
				tabName: "Роли",
				desktopTitle: "Гибкая система ролей",
				desktopContent: (
					<p>
						Создай несколько должностей и&nbsp;настрой доступ для каждой. Можно
						создать нестандартные должности, строго подходящие под бизнес-процессы
						предприятия.
					</p>
				),
				detailLink: pagesLinks.staff,
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/back-office-accounting-2.png"
						alt="управление персоналом в ресторане"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				desktopContentWidth: 500,
				tabName: "Электронные чаевые",
				desktopTitle: "Дай возможность заработать больше",
				desktopContent: (
					<p>
						Подключим систему электронных чаевых: &laquo;Нетмонет&raquo;,
						CloudTips или любую другую. Гость перейдёт по&nbsp;QR-коду
						на&nbsp;чеке и&nbsp;оставит благодарность конкретному официанту.
					</p>
				),
				detailLink: pagesLinks.registration,
				mediaContent: (
					<StaticImage
						className={mediaContent.image}
						src="./assets/back-office-accounting-3.png"
						alt="электронные чаевые"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
		],
	}
}
